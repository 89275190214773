a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

ul,
li {
  list-style-type: none !important;
}

.cell-shrink {
  width: 1px;
  white-space: nowrap;
}
